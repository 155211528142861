@import '../../../scss/theme-bootstrap';

.content-block-video {
  .video-launch-trigger {
    cursor: pointer;
    z-index: 2;
  }
  .video-play {
    width: 100%;
    height: 100%;
  }
  .play-icon {
    position: absolute;
    display: block;
    width: 22%;
    #{$ldirection}: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    @media #{$medium-up} {
      width: 90px;
    }
    &.color-white {
      path,
      polygon {
        fill: $color-white;
      }
    }
    &.play-icon--bottom {
      transform: none;
      top: auto;
      bottom: 20px;
      #{$ldirection}: 15px;
    }
  }
}
